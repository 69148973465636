<!--
 * @Date: 2024-04-02 09:41:20
 * @LastEditTime: 2024-05-11 14:27:29
 * @Author: wangyongjie
 * @Note:  
-->
<template>
  <div id="law">
    <!-- 国家法律法规数据库：https://flk.npc.gov.cn/index.html -->
    <a-row :gutter="20">
      <a-col :span="10">
        <h2>中华人民共和国劳动法: <a target="_blank" href="https://flk.npc.gov.cn/index.html">[来源]</a></h2>
        <a-spin tip="加载中..." :spinning="loading">
          <iframe src="https://gitcdn.xiaodongxier.com/file/中华人民共和国劳动法.pdf" frameborder="0"
            @load="handleLoad"></iframe>
        </a-spin>
      </a-col>

      <a-col :span="10">
        <h2>中华人民共和国劳动合同法: <a target="_blank" href="https://flk.npc.gov.cn/index.html">[来源]</a></h2>
        <a-spin tip="加载中..." :spinning="loading">
          <iframe src="https://gitcdn.xiaodongxier.com/file/中华人民共和国劳动合同法.pdf" frameborder="0"
            @load="handleLoad"></iframe>
        </a-spin>
      </a-col>
      <a-col :span="4">
        <h2>其他参考：</h2>
        <ol>
          <li>
            <a href="https://hrss.hangzhou.gov.cn/art/2020/9/4/art_1587936_56272947.html"
              target="_blank">《中华人民共和国劳动合同法》中关于经济补偿金的那些事</a>
          </li>
          <li>
            <a href="https://www.bjhd.gov.cn/ztzx/2020zt/zmhdxbk/zdgzbzssm/" target="_blank">最低工资标准是什么？
              职工被辞经济补偿怎么算(北京市海淀区人民政府)</a>
          </li>
          <li>
            <a href="https://rsj.beijing.gov.cn/xwsl/mtgz/201912/t20191206_920452.html"
              target="_blank">解除劳动合同经济补偿怎么算？(北京市人力资源和社会保障局2019年)</a>
          </li>
          <li>
            <a href="https://www.beijing.gov.cn/gongkai/hygq/201912/t20191214_1837478.html"
              target="_blank">经济补偿金3倍封顶基数执行新标准(北京市人民政府2019年)</a>
          </li>

          <li>
            <a href="https://sh.12348.gov.cn/sites/12348/news-detail.jsp?category=lpa.JudicialCase&entityid=511849aa3a1f482399f3aad4300f004d"
              target="_blank">单位搬迁外地，劳动者不愿意去辞职有经济补偿吗？</a>
          </li>
          <li>
            <a href="https://rsj.sh.gov.cn/trdhy_17355/20200617/t0035_1382880.html"
              target="_blank">别混淆了“经济补偿”和“经济赔偿”</a>
          </li>
          <li>
            <a href="https://gchzfy.hncourt.gov.cn/public/detail.php?id=3294"
              target="_blank">解除或终止劳动合同经济补偿金支付的61种情形及其标准</a>
          </li>
          <!-- <li>
            <a href="" target="_blank"> </a>
          </li> -->
        </ol>
      </a-col>
    </a-row>


  </div>
</template>

<script>
export default {
  name: 'LawPage',
  data() {
    return {
      description: '法律',
      loading: true
    }
  },
  methods: {
    handleLoad() {
      // alert("111")
      this.loading = false;
    }
  }
}
</script>
<style lang="less" scoped>
iframe {
  width: 100%;
  height: 80vh;
}

a {
  font-size: 14px;
}
</style>